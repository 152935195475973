import isEmpty from 'lodash/isEmpty';
import { ARABIC_LANG_LAYOUT, ARABIC_LANG_API_PAYLOAD, ARABIC_MONTHS } from '../../../constants/index';
import moment from 'moment';

export const getProcessedEventDate = (date, language = 'en') => {
  let lang;
  if (language === ARABIC_LANG_LAYOUT) {
    lang = ARABIC_LANG_API_PAYLOAD;
  } else {
    lang = language;
  }
  date = date?.slice(0, -1);
  const dateFormat = new Date(date);
  let day = dateFormat.getDate();
  if (day < 10) {
    day = '0' + day;
  }
  const formattedDate = {
    day,
    month: dateFormat.toLocaleString(lang, { month: 'short' }),
    year: dateFormat.getFullYear(),
  };
  return formattedDate;
};

export const addLeadingZero = num => {
  return parseInt(num) < 10 ? '0' + num : num;
}

export const addDays = (days, date = new Date()) => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export const dateParseToMilliseconds = (date) => {
  return date && Date.parse(date);
}

export const formatDateTime = (date) => {
  let formattedDate = moment.utc(date).format('YYYYMMDDTHHmmssZ');
  return formattedDate.replace('+00:00', 'Z');
}

export const getDateInFormat = (dateStr, formatStr = 'DD/MM/YYYY') => {
  return dateStr && moment(dateStr).format(formatStr);
}

export const getUTCDateInUAEFormat = (dateStr, formatStr = 'DD/MM/YYYY') => {
  const targetTime = new Date(dateStr);
  const tzDifference = targetTime.getTimezoneOffset();
  const eventTimeString = new Date(targetTime.getTime() + tzDifference * 60 * 1000);

  return moment(eventTimeString).format(formatStr);
}

export const getDateInFormatSchedule = (startDate, endDate) => {
  let eventStartYear = getUTCDateInUAEFormat(startDate, 'YYYY');
  let eventEndYear = getUTCDateInUAEFormat(endDate, 'YYYY');

  let eventDate;
  let eventTime = getUTCDateInUAEFormat(startDate, 'hh:mm A')
    + ' - ' + getUTCDateInUAEFormat(endDate, 'hh:mm A');

  if (eventStartYear == eventEndYear) {
    eventDate = getUTCDateInUAEFormat(startDate, 'DD MMM')
      + ' - ' + getUTCDateInUAEFormat(endDate, 'DD MMM, YYYY');
  } else {
    eventDate = getUTCDateInUAEFormat(startDate, 'DD MMM, YYYY')
      + ' - ' + getUTCDateInUAEFormat(endDate, 'DD MMM, YYYY');
  }

  return { 'eventDate': eventDate, 'eventTime': eventTime };
}

export const formatUEADateTimeToUTC = (date) => {
  //It is UAE time so defining timeoff with utc
  let formattedDate = moment.utc(`${date}+04:00`).format('YYYYMMDDTHHmmssZ');
  return formattedDate.replace('+00:00', 'Z');
}

export const formatArabicLangMonth = (date) => {
  if (isEmpty(date)) return
  const arabicMonths = ARABIC_MONTHS;
  date = new Date(date)
  return arabicMonths[date.getMonth()]
}

export const formatArabicLangDate = (date) => {
  if (isEmpty(date)) return
  const dayNumber = date.split('-')[2]
  const year = date.split('-')[0]
  const arabicDays = ['الأحد', 'الإثنين', 'الثلاثاء', 'الأربعاء', 'الخميس', 'الجمعة', 'السبت'];
  const arabicMonths = ['يناير', 'فبراير', 'مارس', 'أبريل', 'مايو', 'يونيو', 'يوليو', 'أغسطس', 'سبتمبر', 'أكتوبر', 'نوفمبر', 'ديسمبر'];
  date = new Date(date)
  return { year, day: dayNumber, dayName: arabicDays[date.getDay()], month: arabicMonths[date.getMonth()] }
}

export function formatTimeToAr(str) {
  return typeof str === 'string'
    ? str.replace(/PM|AM/g, match => (match === 'PM' ? 'مساءً' : 'صباحًا'))
    : '';
}

export function formatTimeToArV2(str) {
  return typeof str === 'string'
    ? str.replace(/PM|AM/g, match => (match === 'PM' ? 'م' : 'ص'))
    : '';
}