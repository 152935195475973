import React from 'react';
import { get } from 'lodash';

import { getPriceRange } from '../../../utils/getPriceRange';
import { getPageName } from '../../../utils/getPageComponentInfo';
import CarouselCard from '../../molecules/CarouselCard';
import { EVENT_TYPE, LISTING_TYPE, TAGS } from '../../../../constants/index';
import { getRedirectionUrl } from '../../../utils/getRedirectionUrl';
import { getCardCategoryTags } from '../../../utils/getCardCategoryTags';

const FavoriteItem = props => {
  const { item, googlePlacesAPI = "", language, onClickItem } = props;
  const dictionary = props && props.dictionary;

  const ratingOutOf = (dictionary && dictionary('outOf5')) || '';

  const {
    startDate,
    endDate,
    name,
    location,
    time,
    googlePlaceId,
    defaultGooglePlaceId,
    contentType,
    viewsCount,
    ...rest
  } = item;

  let categoryTags =
    getCardCategoryTags(
      item,
      dictionary && dictionary(`${contentType}${TAGS}`)
    ) || [];
  let cardType = LISTING_TYPE;
  let tripAdvisorNumberOfReviews,
    tripAdvisorRatingImageURL = '';
  let imageCtaUrl = '';
  let other = {};
  const itemContentType = item.contentType
    ? item.contentType.toLowerCase()
    : item.category.toLowerCase();

  if (contentType === EVENT_TYPE || itemContentType === EVENT_TYPE) {
    cardType = EVENT_TYPE;

    const featuredEvent = item.isFeatured
      ? dictionary && dictionary('featuredText')
      : '';

    other = {
      openTime: item.openTime,
      closeTime: item.closeTime,
      umbrellaEventCount: item.umbrellaEventCount,
      isEventListing: true,
      peopleInterested:
        item.interestedCount &&
        `${item.interestedCount} ${dictionary && dictionary('interestedText')}`,
      eventType: {
        featured: featuredEvent,
        isPaid: item.eventType,
      },
      locationTitle:
        item.location_Titles &&
        item.location_Titles.length > 0 &&
        item.location_Titles[0],
      region:
        item.location_Regions &&
        item.location_Regions.length > 0 &&
        item.location_Regions[0],
      urlSlug: item.urlSlug,
    };

    if (item.contentType && item.contentType.toLowerCase() === EVENT_TYPE) {
      categoryTags.push(item.isPaid ? "Ticketed" : "Free")
    }

    // eventCategories
    if (item.eventCategories && item.eventCategories.length > 0) {
      categoryTags = categoryTags.concat(item.eventCategories);
    }
    if (other.eventType && other.eventType.isPaid) {
      if (categoryTags && categoryTags.length > 0) {
        categoryTags[1] = other.eventType.isPaid;
      } else {
        categoryTags[0] = other.eventType.isPaid;
      }
    }

    imageCtaUrl = getRedirectionUrl(item, language);
  } else {
    categoryTags = getCardCategoryTags(
      item,
      dictionary && dictionary(`${itemContentType}${TAGS}`)
    );

    if (categoryTags.length < 1) {
      categoryTags = item.suitableForTags
    }

    tripAdvisorNumberOfReviews = item.tripAdvisorNumberOfReviews;
    tripAdvisorRatingImageURL = item.tripAdvisorRatingImageURL;
    imageCtaUrl = get(item, 'itemUrl', '');
  }

  const getThumbnailSrc = () => {
    if (itemContentType == EVENT_TYPE) { // added check since format changed for event images
      return {
        src: get(item, 'thumbnailImage.src', ''),
        alt: get(item, 'name', ''),
      }
    }

    return item.thumbnailImage?.src
      ? {
        src: get(item, 'thumbnailImage.3x2-card.small', ''),
        alt: get(item, 'thumbnailImage.alt', ''),
      }
      : typeof item.thumbnailImage === 'string'
        ? {
          src: item.thumbnailImage,
          alt: get(item, 'name', ''),
        }
        : {
          src: '',
          alt: get(item, 'name', ''),
        };
  }

  const thumbnailSrc = getThumbnailSrc()

  const thumbnailImage = {
    value: thumbnailSrc,
  };

  let priceTagData = item.priceTagsKeys
  if (typeof priceTagData === 'string' && priceTagData.startsWith("[")) {
    priceTagData = JSON.parse(priceTagData)
  }
  priceTagData = priceTagData && priceTagData.length > 0 ? getPriceRange(priceTagData) : [];

  return (
    <React.Fragment>
      <CarouselCard
        title={name}
        desktopImage={thumbnailImage}
        mobileImage={thumbnailImage}
        aspectRatioNeeded={false}
        imageCtaUrl={imageCtaUrl}
        ctaLink={categoryTags}
        cardType={cardType}
        isFavoriteItem={true}
        description={''}
        ratingOutOf={ratingOutOf}
        pageName={getPageName(props)}
        componentName={props.componentName}
        startDate={startDate}
        endDate={endDate}
        location={location}
        highlightWords={''}
        viewsCount={viewsCount}
        expenseRange={priceTagData}
        num_reviews={tripAdvisorNumberOfReviews}
        rating_image_url={tripAdvisorRatingImageURL}
        googlePlaceId={googlePlaceId}
        defaultGooglePlaceId={defaultGooglePlaceId}
        language={language}
        googlePlacesAPI={googlePlacesAPI}
        onClickItem={onClickItem}
        itemUniqueKey={item.id}
        {...rest}
        {...other}
      />
    </React.Fragment>
  );
}

function propsAreEqual(prevProps, nextProps) {
  return (
    prevProps.item &&
    nextProps.item &&
    prevProps.item.itemUniqueKey === nextProps.item.itemUniqueKey
  );
}

export default React.memo(FavoriteItem, propsAreEqual);
